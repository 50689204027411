import { Button, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { Oval } from "react-loader-spinner";

const CustomButton = ({ title, icon, onClick, loading, b_color, height }) => {
  return (
    <Button
      onClick={onClick}
      className={"button_style"}
      disabled={loading ? true : false}
      color={"#fff"}
      style={{
        backgroundColor: b_color || "#0175c2",
        height: height || "30px",
        // fontWeight: "bold",
      }}
    >
      {loading || icon}
      {loading ? (
        <Oval
          height={25}
          width={25}
          color="#fff"
          wrapperStyle={{}}
          wrapperClass={"loader"}
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#fff"
          strokeWidth={5}
          strokeWidthSecondary={5}
        />
      ) : (
        title
      )}
    </Button>
  );
};

export default CustomButton;
