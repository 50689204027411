import { Center, Container, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import CustomInput from "../components/CustomInput";
import { FiEye, FiEyeOff } from "react-icons/fi";
import CustomButton from "../components/ButtonComp";

const Login = () => {
  return (
    <Container
      bg={"#f1f1f1"}
      h={"100vh"}
      w={"full"}
      maxW={"full"}
      centerContent
      justifyContent={"center"}
    >
      <Flex
        backgroundColor={"#fff"}
        maxW={"container.sm"}
        w={"full"}
        shadow={"base"}
        borderRadius={"xl"}
        overflow={"hidden"}
        direction={{ base: "column", sm: "row" }}
      >
        <Image
          //   src="bg.jpg"
          src="https://images.pexels.com/photos/719396/pexels-photo-719396.jpeg?cs=srgb&dl=pexels-gabriel-peter-719396.jpg&fm=jpg"
          alt="login"
          w={{ base: "full", sm: "400px" }}
          maxW={{ base: "full", sm: "250px" }}
          maxH={{ base: "200px", sm: "xs" }}
        ></Image>
        <Container w={"full"} maxW={"full"} p={5}>
          {/* <Heading fontSize={"lg"}>Login</Heading> */}
          <CustomInput
            label1={"User Name"}
            onlyone
            placeholder1={"Enter User Name"}
            // value1={id}
            // onChange1={(e) => setId(e.target.value)}
          />
          <CustomInput
            label1={"Password"}
            onlyone
            placeholder1={"Enter Password"}
            // type1={show ? "text" : "password"}
            // value1={password}
            // onChange1={(e) => setPassword(e.target.value)}
            // right={
            //   !show ? (
            //     <FiEye cursor={"pointer"} size={18} onClick={handleClick} />
            //   ) : (
            //     <FiEyeOff cursor={"pointer"} size={18} onClick={handleClick} />
            //   )
            // }
          />
          <Text align={"right"} mt={2}>
            Forgot Password?
          </Text>
          <Center mt={5}>
            <CustomButton title={"Login"} />
          </Center>
        </Container>
      </Flex>
    </Container>
  );
};

export default Login;
