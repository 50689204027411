import React, { useEffect, useState } from "react";
import store from "../../redux/store";
import {
  Card,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  SimpleGrid,
  Spacer,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import {
  FiChevronLeft,
  FiChevronRight,
  FiEdit,
  FiEye,
  FiSearch,
} from "react-icons/fi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DisplayData from "../../components/DisplayData";
import { get_customer_list } from "../../redux/customerSlice";
import TableView from "../../components/TableView";
import CustomToolTip from "../../components/CustomTooltip";

const CustomerList = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState(5);
  const [page, setpage] = useState(1);
  const [initialValue, setInitialValue] = useState(1);
  const { customer_list, total_customer_count } = useSelector(
    (state) => state.customers
  );

  useEffect(() => {
    store.dispatch(get_customer_list({ page: page, rows: rows }));
  }, []);
  const onPrevious = () => {
    initialValue == 1 || setInitialValue(Number(initialValue) - Number(rows));
    page == 1 || setpage(page - 1);
    page === 1 ||
      store.dispatch(get_customer_list({ page: page - 1, rows: rows }));
  };

  const onNext = () => {
    rows * page >= total_customer_count ||
      setInitialValue(Number(initialValue) + Number(rows));
    rows * page >= total_customer_count || setpage(page + 1);
    rows * page >= total_customer_count ||
      store.dispatch(get_customer_list({ page: page + 1, rows: rows }));
  };

  return (
    <>
      <Card
        bg={"#fff"}
        paddingInline={5}
        paddingBlock={2}
        flexDirection={["column", "column", "row"]}
        align={["start", "start", "center"]}
      >
        <Heading fontSize={"xl"}>Customer List</Heading>
        <Spacer />
        <Flex alignItems={"center"} gap={2} mr={2}>
          {/* <Text>Item limit :</Text> */}
          <Select
            placeholder=""
            size={"md"}
            w={"15"}
            value={rows}
            onChange={(e) => {
              store.dispatch(
                get_customer_list({ page: page, rows: e.target.value })
              );
              setRows(e.target.value);
            }}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Select>
        </Flex>
        <InputGroup bg={"#f4f5f7"} maxW={"sm"} borderRadius={5}>
          <Input placeholder="Search..." />
          <InputRightElement>
            <FiSearch />
          </InputRightElement>
        </InputGroup>
      </Card>
      <SimpleGrid minChildWidth="xs" spacing="10px">
        {customer_list.map((el, i) => (
          <Card
            paddingInline={5}
            paddingBlock={2}
            cursor={"pointer"}
            onClick={() =>
              navigate("/customer-list/customer-detail", { state: el.id })
            }
          >
            <DisplayData
              fs={"16px"}
              v_color={"#007bc9"}
              fw={"bold"}
              //   value={el.name}
              value={el.userid}
            />
            <DisplayData title={"Address"} value={el.address} />
            <DisplayData title={"Mobile No."} value={el.mobile} />
            <DisplayData title={"Email"} value={el.email} />
          </Card>
        ))}
      </SimpleGrid>
      {/* <div>
        <TableView
          headData={["S.No.", "userid", "name", "type", "tradename", "Action"]}
          body={customer_list.map((el, i) => (
            <Tr>
              <Td textAlign="center">{i + initialValue}</Td>
              <Td textAlign="center">{el.userid}</Td>
              <Td textAlign="center">{el.name}</Td>
              <Td textAlign="center">{el.type}</Td>
              <Td textAlign="center">{el.tradename}</Td>
              <Td textAlign="center">
                {
                  <Flex justify={"center"} gap={4}>
                    <CustomToolTip
                      button={
                        <FiEye
                          size={20}
                          color="#0175c2"
                          cursor={"pointer"}
                          onClick={() =>
                            navigate("/customer-list/customer-detail", {
                              state: el.id,
                            })
                          }
                        />
                      }
                      mess={"View Customer"}
                    />
                    <CustomToolTip
                      button={<FiEdit size={20} cursor={"pointer"} />}
                      mess={"Edit Customer"}
                    />
                  </Flex>
                }
              </Td>
            </Tr>
          ))}
          initialValue={initialValue}
          page={page}
          rows={rows}
          total_value={total_customer_count}
          pagination
          onChange={(e) => {
            setpage(1);
            store.dispatch(
              get_customer_list({ page: 1, rows: e.target.value })
            );
            setRows(e.target.value);
          }}
          onPrevious={() => {
            initialValue == 1 ||
              setInitialValue(Number(initialValue) - Number(rows));
            page == 1 || setpage(page - 1);
            page == 1 ||
              store.dispatch(get_customer_list({ page: page - 1, rows: rows }));
          }}
          onNext={() => {
            rows * page >= total_customer_count ||
              setInitialValue(Number(initialValue) + Number(rows));
            rows * page >= total_customer_count || setpage(page + 1);
            rows * page >= total_customer_count ||
              store.dispatch(get_customer_list({ page: page + 1, rows: rows }));
          }}
        />
      </div> */}
      <Card
        alignItems={"center"}
        alignSelf={"end"}
        gap={3}
        flexDirection={"row"}
      >
        <FiChevronLeft
          cursor={"pointer"}
          color={page == 1 ? "gray" : "#007bc9"}
          size={35}
          onClick={onPrevious}
        />
        <Flex gap={3}>
          <Text>{initialValue}</Text>-
          <Text>
            {rows * page >= total_customer_count
              ? total_customer_count
              : rows * page}
          </Text>
          <Text>of</Text>
          <Text>{total_customer_count}</Text>
        </Flex>
        <FiChevronRight
          cursor={"pointer"}
          color={rows * page >= total_customer_count ? "gray" : "#007bc9"}
          size={35}
          onClick={onNext}
        />
      </Card>
    </>
  );
};

export default CustomerList;
