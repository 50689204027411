import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { get_vehicle_detail } from "../../utils/api";
import {
  Box,
  Card,
  Flex,
  Heading,
  SimpleGrid,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import CustomButton from "../../components/ButtonComp";
import { BiArrowBack } from "react-icons/bi";
import DisplayData from "../../components/DisplayData";
import Loader from "../../components/Loader";
import ImageModal from "../../components/ImageModal";

const VehicleDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [url, setUrl] = useState("");
  useEffect(() => {
    get_vehicle_detail(location.state, setLoading).then((el) => {
      if (el.success) {
        setData(el.reponselist);
      } else {
      }
    });
  }, []);

  return (
    <>
      <Card
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justify={"space-between"}
        paddingInline={5}
        paddingBlock={2}
      >
        <Heading fontSize={"xl"}>Vehicle Details</Heading>
        <CustomButton
          icon={<BiArrowBack color="#fff" />}
          title={"Back"}
          height={"30px"}
          b_color={"#000"}
          onClick={() => navigate(-1)}
        />
      </Card>
      {loading ? (
        <Card p={5} justify={"center"} alignItems={"center"}>
          <Loader />
        </Card>
      ) : (
        <Flex gap={3} direction={["column", "column", "row", "row"]}>
          <Card
            paddingInline={5}
            paddingBlock={2}
            flex={1}
            alignSelf={"start"}
            w={"full"}
          >
            <Flex justify={"space-between"}>
              <DisplayData
                fs={"16px"}
                v_color={"#007bc9"}
                fw={"bold"}
                value={data.vehicleno}
              />
              <DisplayData v_color={"#f37022"} fw={"bold"} value={data.type} />
            </Flex>
            <DisplayData w={100} title={"Make"} value={data.make} />
            <DisplayData w={100} title={"Chassis No"} value={data.chassis} />
            <DisplayData w={100} title={"Capacity"} value={data.capacity} />
            <DisplayData
              w={100}
              title={"Supply"}
              value={data.supply == 2 ? "Yes" : "No"}
            />
            <DisplayData
              w={100}
              title={"Delivery"}
              value={data.delivery == 2 ? "Yes" : "No"}
            />
          </Card>
          <VStack flex={1}>
            <Box w={"full"}>
              <Heading fontSize={"lg"} mb={2}>
                Documents Details
              </Heading>
              {data?.documents?.length == 0 ? (
                <Card paddingInline={5} paddingBlock={2} maxW={"full"}>
                  <Text textAlign={"center"}>No Data</Text>
                </Card>
              ) : (
                <SimpleGrid minChildWidth="full" spacing="10px">
                  {data?.documents?.map((el, i) => (
                    <Card key={i} paddingInline={5} paddingBlock={2}>
                      <Flex justify={"space-between"}>
                        <DisplayData
                          w={"100px"}
                          title={"Name"}
                          value={el.name}
                        />
                        <CustomButton
                          title={"View"}
                          height={"25px"}
                          onClick={() => {
                            setUrl(el.url);
                            onOpen();
                          }}
                        />
                      </Flex>

                      <DisplayData
                        w={"100px"}
                        title={"doc_name"}
                        value={el.doc_name}
                      />
                    </Card>
                  ))}
                </SimpleGrid>
              )}
            </Box>
            {/* <Box w={"full"}>
              <Heading fontSize={"lg"}>Bank Details</Heading>
              {data?.banklist?.length == 0 ? (
                <Card paddingInline={5} paddingBlock={2} maxW={"full"}>
                  <Text textAlign={"center"}>No Data</Text>
                </Card>
              ) : (
                <SimpleGrid minChildWidth="full" spacing="10px">
                  {data?.banklist?.map((el, i) => (
                    <Card key={i} paddingInline={5} paddingBlock={2}>
                      <Flex justify={"space-between"}>
                        <DisplayData
                          w={"100px"}
                          title={"Name"}
                          value={el.name}
                        />
                        <CustomButton
                          title={"View"}
                          height={"25px"}
                          onClick={() => {
                            setUrl(el.url);
                            onOpen();
                          }}
                        />
                      </Flex>
                      <DisplayData
                        w={"100px"}
                        title={"Bank Name"}
                        value={el.bankname}
                      />
                      <DisplayData
                        w={"100px"}
                        title={"Account No."}
                        value={el.account_no}
                      />
                      <DisplayData
                        w={"100px"}
                        title={"Branch Name"}
                        value={el.branch_name}
                      />
                      <DisplayData
                        w={"100px"}
                        title={"IFSC Code"}
                        value={el.ifsc_code}
                      />
                    </Card>
                  ))}
                </SimpleGrid>
              )}
            </Box> */}
          </VStack>
        </Flex>
      )}
      <ImageModal url={url} onClose={onClose} isOpen={isOpen} />
    </>
  );
};

export default VehicleDetail;
