import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../utils/utils";

export const get_driver_list = createAsyncThunk("driver", async (data) => {
  console.log(data);
  const body = new FormData();
  body.append("userid", 1);
  body.append("ingress", 1);
  body.append("page", data.page);
  body.append("limit", data.rows);
  const response = await fetch(baseUrl + "driverlist", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  console.log(res);
  return res;
});

const initialState = {
  driver_list: [],
  loading: false,
  total_driver_count: 0,
};
const driverSlice = createSlice({
  name: "users",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(get_driver_list.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(get_driver_list.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(get_driver_list.fulfilled, (state, action) => {
      state.loading = false;
      const data = action.payload;
      state.loading = false;
      if (data.success) {
        state.total_driver_count = data.totalcount;
        state.driver_list = data.reponselist;
      } else {
        state.driver_list = [];
      }
    });
  },
});
export default driverSlice.reducer;
