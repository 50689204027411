import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { get_dp_detail } from "../../utils/api";
import {
  Box,
  Card,
  Flex,
  Heading,
  Input,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import CustomButton from "../../components/ButtonComp";
import { BiArrowBack } from "react-icons/bi";
import DisplayData from "../../components/DisplayData";
import Loader from "../../components/Loader";
import ImageModal from "../../components/ImageModal";

const DPdetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [url, setUrl] = useState("");

  useEffect(() => {
    get_dp_detail(location.state, setLoading).then((el) => {
      if (el.success) {
        setData(el.reponselist);
      } else {
      }
    });
  }, []);

  return (
    <>
      <Card
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justify={"space-between"}
        paddingInline={5}
        paddingBlock={2}
      >
        <Heading fontSize={"xl"}>DP Details</Heading>
        <CustomButton
          icon={<BiArrowBack color="#fff" />}
          title={"Back"}
          height={"30px"}
          b_color={"#000"}
          onClick={() => navigate(-1)}
        />
      </Card>
      {loading ? (
        <Card p={5} justify={"center"} alignItems={"center"}>
          <Loader />
        </Card>
      ) : (
        <Flex gap={3} direction={["column", "column", "row", "row"]}>
          <Stack flex={1}>
            <Card
              paddingInline={5}
              paddingBlock={2}
              w={"full"}
              alignSelf={"start"}
            >
              {/* <DisplayData title={"Name"} value={data.name} /> */}
              <DisplayData value={data.uuid} v_color={"#f37022"} />
              <DisplayData w={"100px"} title={"GST No."} value={data.gst} />
              <DisplayData w={"100px"} title={"PAN No."} value={data.pan} />
              <DisplayData
                w={"100px"}
                title={"Trade Name"}
                value={data.tradename}
              />
              <DisplayData
                w={"100px"}
                title={"Legal Name"}
                value={data.legalname}
              />
              <DisplayData w={"100px"} title={"Address"} value={data.address} />
              <DisplayData
                w={"100px"}
                title={"District"}
                value={data.district}
              />
              <DisplayData w={"100px"} title={"State"} value={data.state} />
              <DisplayData w={"100px"} title={"Pincode"} value={data.pincode} />
              <DisplayData
                w={"100px"}
                title={"Mobile 1"}
                value={data.mobile1}
              />
              <DisplayData
                w={"100px"}
                title={"Mobile 2"}
                value={data.mobile2}
              />
              <DisplayData w={"100px"} title={"Email 1"} value={data.email1} />
              <DisplayData w={"100px"} title={"Email 2"} value={data.email2} />
              <DisplayData
                w={"100px"}
                title={"address1"}
                value={data.address1}
              />
              <DisplayData
                w={"100px"}
                title={"address2"}
                value={data.address2}
              />
            </Card>
          </Stack>
          <VStack flex={1} align={"start"}>
            <Box w={"full"}>
              <Heading fontSize={"lg"} mb={2}>
                Documents Details
              </Heading>
              {data?.documents?.length == 0 ? (
                <Card paddingInline={5} paddingBlock={2} maxW={"full"}>
                  <Text textAlign={"center"}>No Data</Text>
                </Card>
              ) : (
                <SimpleGrid minChildWidth="full" spacing="10px">
                  {data?.documents?.map((el, i) => (
                    <Card
                      key={i}
                      paddingInline={5}
                      paddingBlock={2}
                      maxW={"full"}
                    >
                      <Flex justify={"space-between"}>
                        <DisplayData
                          w={"100px"}
                          title={"Name"}
                          value={el.name}
                        />
                        <CustomButton
                          title={"View"}
                          height={"25px"}
                          onClick={() => {
                            setUrl(el.url);
                            onOpen();
                          }}
                        />
                      </Flex>

                      <DisplayData
                        w={"100px"}
                        title={"doc_name"}
                        value={el.doc_name}
                      />
                    </Card>
                  ))}
                </SimpleGrid>
              )}
            </Box>

            <Box w={"full"}>
              <Heading fontSize={"lg"}>Bank Details</Heading>
              {data?.banklist?.length == 0 ? (
                <Card paddingInline={5} paddingBlock={2} maxW={"full"}>
                  <Text textAlign={"center"}>No Data</Text>
                </Card>
              ) : (
                <SimpleGrid minChildWidth="full" spacing="10px">
                  {data?.banklist?.map((el, i) => (
                    <Card key={i} paddingInline={5} paddingBlock={2}>
                      <Flex justify={"space-between"}>
                        <DisplayData
                          w={"100px"}
                          title={"Name"}
                          value={el.name}
                        />
                        <CustomButton
                          title={"View"}
                          height={"25px"}
                          onClick={() => {
                            setUrl(el.url);
                            onOpen();
                          }}
                        />
                      </Flex>
                      <DisplayData
                        w={"100px"}
                        title={"Bank Name"}
                        value={el.bankname}
                      />
                      <DisplayData
                        w={"100px"}
                        title={"Account No."}
                        value={el.account_no}
                      />
                      <DisplayData
                        w={"100px"}
                        title={"Branch Name"}
                        value={el.branch_name}
                      />
                      <DisplayData
                        w={"100px"}
                        title={"IFSC Code"}
                        value={el.ifsc_code}
                      />
                    </Card>
                  ))}
                </SimpleGrid>
              )}
            </Box>
          </VStack>
        </Flex>
      )}
      <ImageModal url={url} onClose={onClose} isOpen={isOpen} />
    </>
  );
};

export default DPdetail;
