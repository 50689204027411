import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { get_driver_detail, get_vendor_detail } from "../../utils/api";
import {
  Box,
  Card,
  Flex,
  Heading,
  Input,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import CustomButton from "../../components/ButtonComp";
import { BiArrowBack } from "react-icons/bi";
import DisplayData from "../../components/DisplayData";
import Loader from "../../components/Loader";
import ImageModal from "../../components/ImageModal";

const VendorDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [url, setUrl] = useState("");
  const [settingData, setSettingData] = useState({});
  useEffect(() => {
    get_vendor_detail(location.state, setLoading).then((el) => {
      if (el.success) {
        setData(el.reponselist);
        setSettingData({
          ...settingData,
          credit_days: el.reponselist.credit_days,
          credit_limit: el.reponselist.credit_limit,
        });
      } else {
      }
    });
  }, []);

  return (
    <>
      <Card
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justify={"space-between"}
        paddingInline={5}
        paddingBlock={2}
      >
        <Heading fontSize={"xl"}>Vendor Details</Heading>
        <CustomButton
          icon={<BiArrowBack color="#fff" />}
          title={"Back"}
          height={"30px"}
          b_color={"#000"}
          onClick={() => navigate(-1)}
        />
      </Card>
      {loading ? (
        <Card p={5} justify={"center"} alignItems={"center"}>
          <Loader />
        </Card>
      ) : (
        <Flex gap={3} direction={["column", "column", "row", "row"]}>
          <Stack flex={1}>
            <Card
              paddingInline={5}
              paddingBlock={2}
              w={"full"}
              alignSelf={"start"}
            >
              <DisplayData
                w={"120px"}
                title={"Vendor ID"}
                value={data.vendorid}
                v_color={"#f37022"}
              />
              <DisplayData w={"120px"} title={"GST No."} value={data.gst} />
              <DisplayData w={"120px"} title={"PAN No."} value={data.pan} />
              <DisplayData
                w={"120px"}
                title={"Trade Name"}
                value={data.tradename}
              />
              <DisplayData
                w={"120px"}
                title={"Legal Name"}
                value={data.legalname}
              />
              <DisplayData w={"120px"} title={"Address"} value={data.address} />
              <DisplayData
                w={"120px"}
                title={"District"}
                value={data.district}
              />
              <DisplayData w={"120px"} title={"State"} value={data.state} />
              <DisplayData w={"120px"} title={"Pincode"} value={data.pincode} />
              <Heading fontSize={"lg"} mt={2}>
                Business Detail
              </Heading>
              <DisplayData
                w={"120px"}
                title={"Business Mobile"}
                value={data.mobile_business}
              />
              <DisplayData
                w={"120px"}
                title={"Business Email"}
                value={data.email_business}
              />
              <DisplayData
                w={"120px"}
                title={"Business Address"}
                value={data.address_business}
              />
              <Heading fontSize={"lg"} mt={2}>
                Personal Detail
              </Heading>
              <DisplayData
                w={"120px"}
                title={"Personal Mobile"}
                value={data.mobile_personal}
              />
              <DisplayData
                w={"120px"}
                title={"Personal Email"}
                value={data.email_personal}
              />
              <DisplayData
                w={"120px"}
                title={"Personal Address"}
                value={data.address_personal}
              />
            </Card>
            <Card
              paddingInline={5}
              paddingBlock={2}
              alignSelf={"start"}
              w={"full"}
            >
              <Heading fontSize={"lg"} mt={2}>
                Settings Detail
              </Heading>
              <DisplayData
                // w={"0px"}
                title={"Privious 3 years any instance of crosiing 10 cr"}
                value={data.turnover_p3 == 2 ? "Yes" : "No"}
              />
              <DisplayData
                w={"170px"}
                title={"Payment Settlement"}
                value={
                  data.credit == 1
                    ? "T + 2"
                    : data.credit == 2
                    ? "T + 7"
                    : data.credit == 3
                    ? "T + 15"
                    : "t + 30"
                }
              />
              <DisplayData
                w={"170px"}
                title={"Bank Guarantee Request"}
                value={data.bank_guarantee_req == 2 ? "Yes" : "No"}
              />
              <DisplayData
                w={"170px"}
                title={"Credit Support from FI request"}
                value={data.credit_fi_req == 2 ? "Yes" : "No"}
              />
              <DisplayData
                align={"center"}
                w={"170px"}
                title={"Credit days"}
                value={
                  <Input
                    type="number"
                    placeholder="Credit days"
                    size={"xs"}
                    value={settingData.credit_days}
                    onChange={(e) =>
                      e.target.value >= 0 &&
                      setSettingData({
                        ...settingData,
                        credit_days: e.target.value,
                      })
                    }
                  />
                }
              />
              <DisplayData
                align={"center"}
                w={"170px"}
                title={"Credit limit"}
                value={
                  <Input
                    type="number"
                    placeholder="Credit limit"
                    size={"xs"}
                    value={settingData.credit_limit}
                    onChange={(e) =>
                      e.target.value >= 0 &&
                      setSettingData({
                        ...settingData,
                        credit_limit: e.target.value,
                      })
                    }
                  />
                }
              />
            </Card>
          </Stack>
          <VStack flex={1} align={"start"}>
            <Box w={"full"}>
              <Heading fontSize={"lg"} mb={2}>
                Documents Details
              </Heading>
              {data?.documents?.length == 0 ? (
                <Card paddingInline={5} paddingBlock={2} maxW={"full"}>
                  <Text textAlign={"center"}>No Data</Text>
                </Card>
              ) : (
                <SimpleGrid minChildWidth="full" spacing="10px">
                  {data?.documents?.map((el, i) => (
                    <Card
                      key={i}
                      paddingInline={5}
                      paddingBlock={2}
                      maxW={"full"}
                    >
                      <Flex justify={"space-between"}>
                        <DisplayData
                          w={"100px"}
                          title={"Name"}
                          value={el.name}
                        />
                        <CustomButton
                          title={"View"}
                          height={"25px"}
                          onClick={() => {
                            setUrl(el.url);
                            onOpen();
                          }}
                        />
                      </Flex>

                      <DisplayData
                        w={"100px"}
                        title={"doc_name"}
                        value={el.doc_name}
                      />
                    </Card>
                  ))}
                </SimpleGrid>
              )}
            </Box>

            <Box w={"full"}>
              <Heading fontSize={"lg"}>Bank Details</Heading>
              {data?.banklist?.length == 0 ? (
                <Card paddingInline={5} paddingBlock={2} maxW={"full"}>
                  <Text textAlign={"center"}>No Data</Text>
                </Card>
              ) : (
                <SimpleGrid minChildWidth="full" spacing="10px">
                  {data?.banklist?.map((el, i) => (
                    <Card key={i} paddingInline={5} paddingBlock={2}>
                      <Flex justify={"space-between"}>
                        <DisplayData
                          w={"100px"}
                          title={"Name"}
                          value={el.name}
                        />
                        <CustomButton
                          title={"View"}
                          height={"25px"}
                          onClick={() => {
                            setUrl(el.url);
                            onOpen();
                          }}
                        />
                      </Flex>
                      <DisplayData
                        w={"100px"}
                        title={"Bank Name"}
                        value={el.bankname}
                      />
                      <DisplayData
                        w={"100px"}
                        title={"Account No."}
                        value={el.account_no}
                      />
                      <DisplayData
                        w={"100px"}
                        title={"Branch Name"}
                        value={el.branch_name}
                      />
                      <DisplayData
                        w={"100px"}
                        title={"IFSC Code"}
                        value={el.ifsc_code}
                      />
                    </Card>
                  ))}
                </SimpleGrid>
              )}
            </Box>
          </VStack>
        </Flex>
      )}
      <ImageModal url={url} onClose={onClose} isOpen={isOpen} />
    </>
  );
};

export default VendorDetail;
