import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { AiOutlineDashboard } from "react-icons/ai";
import { HStack, VStack } from "@chakra-ui/react";
import { FiUser, FiUsers } from "react-icons/fi";
import { TbDiscount2, TbTruckDelivery } from "react-icons/tb";
import { MdOutlineWarehouse } from "react-icons/md";

const Sidebar = () => {
  const location = useLocation();
  const [isActive, setIsActive] = useState(location.pathname);

  useEffect(() => {
    setIsActive(location.pathname);
  }, [location]);
  return (
    <VStack align={"start"} w={"full"} paddingTop={"10px"}>
      <NavLink
        to={"/"}
        onClick={onclick}
        className={`df alc mt10 ph ${
          isActive == "/"
            ? "link activelink-bg link-br"
            : "link unactivelink-bg"
        }`}
      >
        <AiOutlineDashboard size={25} />
        Dashboard
      </NavLink>
      <NavLink
        onClick={onclick}
        to={"products"}
        className={`df alc mt10 ph ${
          isActive.split("/")[1] == "products"
            ? "link activelink-bg link-br"
            : "link unactivelink-bg"
        }`}
      >
        <FiUser size={25} />
        Products
      </NavLink>
      <NavLink
        onClick={onclick}
        to={"vendors-list"}
        className={`df alc mt10 ph ${
          isActive.split("/")[1] == "vendors-list"
            ? "link activelink-bg link-br"
            : "link unactivelink-bg"
        }`}
      >
        <FiUser size={25} />
        Vendor
      </NavLink>
      <NavLink
        onClick={onclick}
        to={"driver-list"}
        className={`df alc mt10 ph ${
          isActive.split("/")[1] == "driver-list"
            ? "link activelink-bg link-br"
            : "link unactivelink-bg"
        }`}
      >
        <FiUser size={25} />
        Driver
      </NavLink>
      <NavLink
        onClick={onclick}
        to={"vehicle-list"}
        className={`df alc mt10 ph ${
          isActive.split("/")[1] == "vehicle-list"
            ? "link activelink-bg link-br"
            : "link unactivelink-bg"
        }`}
      >
        <TbTruckDelivery size={25} />
        Vehicle
      </NavLink>
      <NavLink
        onClick={onclick}
        to={"stock-point"}
        className={`df alc mt10 ph ${
          isActive.split("/")[1] == "stock-point"
            ? "link activelink-bg link-br"
            : "link unactivelink-bg"
        }`}
      >
        <FiUser size={25} />
        Stock Point
      </NavLink>
      <NavLink
        onClick={onclick}
        to={"dp-list"}
        className={`df alc mt10 ph ${
          isActive.split("/")[1] == "dp-list"
            ? "link activelink-bg link-br"
            : "link unactivelink-bg"
        }`}
      >
        <FiUser size={25} />
        DP List
      </NavLink>
      <NavLink
        onClick={onclick}
        to={"godown-list"}
        className={`df alc mt10 ph ${
          isActive.split("/")[1] == "godown-list"
            ? "link activelink-bg link-br"
            : "link unactivelink-bg"
        }`}
      >
        <MdOutlineWarehouse size={25} />
        Godowns
      </NavLink>
      <NavLink
        onClick={onclick}
        to={"customer-list"}
        className={`df alc mt10 ph ${
          isActive.split("/")[1] == "customer-list"
            ? "link activelink-bg link-br"
            : "link unactivelink-bg"
        }`}
      >
        <FiUsers size={25} />
        Customer List
      </NavLink>
      {/* <NavLink
        onClick={onclick}
        to={"godown-list"}
        className={`df alc mt10 ph ${
          isActive.split("/")[1] == "godown-list"
            ? "link activelink-bg link-br"
            : "link unactivelink-bg"
        }`}
      >
        <TbDiscount2 size={25} />
        Promo Code
      </NavLink> */}
    </VStack>
  );
};

export default Sidebar;
