import { baseUrl } from "./utils";

export const get_vendor_detail = async (id, setLoading) => {
  try {
    setLoading(true);
    const body = new FormData();
    body.append("userid", 1);
    body.append("ingress", 1);
    body.append("id", id);
    const response = await fetch(baseUrl + "vendordetails", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    console.log("policy-list", res);
    setLoading(false);
    return res;
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};

export const get_driver_detail = async (id, setLoading) => {
  try {
    setLoading(true);
    const body = new FormData();
    body.append("userid", 1);
    body.append("ingress", 1);
    body.append("id", id);
    const response = await fetch(baseUrl + "driverdetails", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    console.log("policy-list", res);
    setLoading(false);
    return res;
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};
export const get_godown_detail = async (id, setLoading) => {
  try {
    setLoading(true);
    const body = new FormData();
    body.append("userid", 1);
    body.append("ingress", 1);
    body.append("id", id);
    const response = await fetch(baseUrl + "godowndetails", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    console.log("policy-list", res);
    setLoading(false);
    return res;
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};
export const get_vehicle_detail = async (id, setLoading) => {
  try {
    setLoading(true);
    const body = new FormData();
    body.append("userid", 1);
    body.append("ingress", 1);
    body.append("id", id);
    const response = await fetch(baseUrl + "vehicledetails", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    console.log("policy-list", res);
    setLoading(false);
    return res;
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};
export const get_customer_detail = async (id, setLoading) => {
  try {
    setLoading(true);
    const body = new FormData();
    body.append("userid", 1);
    body.append("ingress", 1);
    body.append("id", id);
    const response = await fetch(baseUrl + "customer-details", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    console.log("policy-list", res);
    setLoading(false);
    return res;
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};
export const get_dp_detail = async (id, setLoading) => {
  try {
    setLoading(true);
    const body = new FormData();
    body.append("userid", 1);
    body.append("ingress", 1);
    body.append("id", id);
    const response = await fetch(baseUrl + "dpdetails", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    console.log("policy-list", res);
    setLoading(false);
    return res;
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};
