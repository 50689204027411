import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../utils/utils";

export const get_customer_list = createAsyncThunk("customers", async (data) => {
  const body = new FormData();
  body.append("userid", 1);
  body.append("ingress", 1);
  body.append("page", data.page);
  body.append("limit", data.rows);
  const response = await fetch(baseUrl + "customer-list", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  console.log("customers", res);
  return res;
});

const initialState = {
  customer_list: [],
  loading: false,
  total_customer_count: 0,
};
const customerSlice = createSlice({
  name: "users",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(get_customer_list.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(get_customer_list.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(get_customer_list.fulfilled, (state, action) => {
      state.loading = false;
      const data = action.payload;
      state.loading = false;
      if (data.success) {
        state.total_customer_count = data.totalcount;
        state.customer_list = data.reponselist;
      } else {
        state.customer_list = [];
      }
    });
  },
});
export default customerSlice.reducer;
