import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../utils/utils";

export const get_stock_list = createAsyncThunk("stock", async (data) => {
  const body = new FormData();
  body.append("userid", 1);
  body.append("ingress", 1);
  body.append("page", data.page);
  body.append("limit", data.rows);
  const response = await fetch(baseUrl + "stockpoints-list", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  console.log(res);
  return res;
});

const initialState = {
  stock_list: [],
  loading: false,
  total_stock_count: 0,
};
const stockSlice = createSlice({
  name: "stock",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(get_stock_list.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(get_stock_list.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(get_stock_list.fulfilled, (state, action) => {
      state.loading = false;
      const data = action.payload;
      state.loading = false;
      if (data.success) {
        state.total_stock_count = data.totalcount;
        state.stock_list = data.reponselist;
      } else {
        state.stock_list = [];
      }
    });
  },
});
export default stockSlice.reducer;
