import { configureStore } from "@reduxjs/toolkit";
import driverSlice from "./driverSlice";
import productSlice from "./productSlice";
import vendorSlice from "./vendorSlice";
import stockSlice from "./stockSlice";
import dplistSlice from "./dplistSlice";
import godownSlice from "./godownSlice";
import vehiclSlice from "./vehiclSlice";
import customerSlice from "./customerSlice";

const store = configureStore({
  reducer: {
    drivers: driverSlice,
    products: productSlice,
    vendors: vendorSlice,
    stocks: stockSlice,
    dplist: dplistSlice,
    godown: godownSlice,
    vehicles: vehiclSlice,
    customers: customerSlice,
  },
});

export default store;
