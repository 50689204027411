import { Card, Center, Flex, Heading } from "@chakra-ui/react";
import React from "react";
import CustomButton from "../../components/ButtonComp";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../components/CustomInput";
import SelectImage from "../../components/SelectImage";

const AddProduct = () => {
  const navigate = useNavigate();

  return (
    <>
      <Card
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justify={"space-between"}
        paddingInline={5}
        paddingBlock={2}
      >
        <Heading fontSize={"xl"}>Add Product</Heading>
        <CustomButton
          icon={<BiArrowBack color="#fff" />}
          title={"Back"}
          height={"30px"}
          b_color={"#000"}
          onClick={() => navigate(-1)}
        />
      </Card>
      <Card paddingInline={5}>
        <CustomInput
          label1={"Product name"}
          label2={"HSN code"}
          placeholder1={"Product name"}
          placeholder2={"HSN code"}
        />
        <CustomInput
          label1={"GST Percentage"}
          label2={"Category"}
          placeholder1={"GST Percentage"}
          placeholder2={"Category"}
        />
        <CustomInput
          label1={"Capacity"}
          label2={"Units"}
          placeholder1={"Capacity"}
          placeholder2={"Units"}
        />
        <CustomInput
          label1={"Specifications or Description"}
          placeholder1={"Specifications or Description"}
          area1
          label2={"Application of the Product"}
          placeholder2={"Application of the Product"}
          area2
        />
        <Flex w={["100%", "100%", "49.5%"]}>
          <SelectImage label={"Upload Image"} mt={3} />
        </Flex>
        <Center marginBlock={5}>
          <CustomButton title={"Submit"} />
        </Center>
      </Card>
    </>
  );
};

export default AddProduct;
