import {
  Center,
  Container,
  Flex,
  Select,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { GrNext, GrPrevious } from "react-icons/gr";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";

const TableView = ({
  headData = [],
  body,
  maxW,
  footer,
  initialValue,
  rows,
  page,
  onPrevious,
  onNext,
  pagination,
  onChange,
  total_value,
}) => {
  return (
    <TableContainer
      bg={"#fff"}
      borderRadius={5}
      flex={1}
      maxWidth={maxW || "full"}
      w={"full"}
      overflow={"scroll"}
      border={"1px solid #88888822"}
      shadow={"sm"}
    >
      <Table variant="simple">
        <Thead bg={"#88888822"}>
          <Tr>
            {headData.map((v, i) => (
              <Th color={"#000"} key={i} textAlign={"center"}>
                {v}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>{body}</Tbody>
      </Table>
      {/* {footer} */}
      {pagination && (
        <Flex
          w={"full"}
          h={"14"}
          justify={"flex-end"}
          alignItems={"center"}
          px={"10"}
          gap={6}
        >
          <Text>Rows per page:</Text>
          <Select
            placeholder=""
            size={"md"}
            w={"15"}
            value={rows}
            onChange={onChange}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Select>
          <Flex gap={3}>
            <Text>{initialValue}</Text>-
            <Text>
              {rows * page >= total_value ? total_value : rows * page}
            </Text>
            <Text>of {total_value}</Text>
          </Flex>
          {/* <GrPrevious
            cursor={"pointer"}
            color={page == 1 ? "gray" : "#007bc9"}
            style={{
              height: 30,
              width: 30,
              padding: 6,
              borderRadius: 5,
            }}
            onClick={onPrevious}
          /> */}
          <FiChevronLeft
            cursor={"pointer"}
            color={page == 1 ? "gray" : "#007bc9"}
            size={35}
            onClick={onPrevious}
          />
          {/* <GrNext
            cursor={"pointer"}
            style={{
              height: 30,
              width: 30,
              padding: 6,
              borderRadius: 5,
            }}
            onClick={onNext}
          /> */}
          <FiChevronRight
            cursor={"pointer"}
            color={rows * page >= total_value ? "gray" : "#007bc9"}
            size={35}
            onClick={onNext}
          />
        </Flex>
      )}
    </TableContainer>
  );
};

export default TableView;
