import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../utils/utils";

export const get_vendor_list = createAsyncThunk("vendor", async (data) => {
  const body = new FormData();
  body.append("userid", 1);
  body.append("ingress", 1);
  body.append("page", data.page);
  body.append("limit", data.rows);
  const response = await fetch(baseUrl + "vendorlist", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  console.log(res);
  return res;
});

const initialState = {
  vendor_list: [],
  loading: false,
  total_vendor_count: 0,
};
const vendorSlice = createSlice({
  name: "vendor",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(get_vendor_list.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(get_vendor_list.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(get_vendor_list.fulfilled, (state, action) => {
      state.loading = false;
      const data = action.payload;
      state.loading = false;
      if (data.success) {
        state.total_vendor_count = data.totalcount;
        state.vendor_list = data.reponselist;
      } else {
        state.vendor_list = [];
      }
    });
  },
});
export default vendorSlice.reducer;
