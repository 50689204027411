import { Button, Center, Flex, Image, Spacer } from "@chakra-ui/react";
import React from "react";

const Header = () => {
  return (
    <Flex h={"full"} w={"full"}>
      <Center
        w={"13rem"}
        minW={"13rem"}
        // borderRight={"1px solid #88888822 "}
        display={["none", "none", "none", "flex"]}
      >
        <Image src="logoname.png" h={"30px"} w={"70%"} alt="LOGO" />
      </Center>

      {/* <Flex
        display={["flex", "flex", "flex", "none"]}
        alignItems={"center"}
        pl={2}
        pr={10}
        w={"full"}
        justify={"space-between"}
      >
        {/* <HiMenu size={25} cursor={"pointer"} onClick={() => onToggle()} /> 
        <Button variant={"link"}>Log Out</Button>
      </Flex> */}
      <Flex
        alignItems={"center"}
        pl={2}
        pr={10}
        w={"full"}
        justify={"flex-end"}
        display={["none", "none", "none", "flex"]}
      >
        <Button variant={"link"}>Log Out</Button>
      </Flex>

      {/* <Stack display={["none", "none", "none", "flex"]}>
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          // finalFocusRef={btnRef}
          size={"xs"}
        >
          <DrawerOverlay />
          <DrawerContent onClick={() => onClose()} maxW={"52"}>
            <Sidebar />
          </DrawerContent>
        </Drawer>
      </Stack> */}
      {/* <CustomPopup
        isOpen={isLOpen}
        onClose={onLClose}
        mess={"Are you sure? you want to log out"}
        title={"Log Out"}
        b_name={"Log Out"}
        onClick={() => {
          localStorage.removeItem("userId");
          onClose();
          navigate("/login");
        }}
      /> */}
    </Flex>
  );
};

export default Header;
