import React, { useEffect, useState } from "react";
import store from "../../redux/store";
import {
  Card,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  SimpleGrid,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { FiChevronLeft, FiChevronRight, FiSearch } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DisplayData from "../../components/DisplayData";
import { get_vehicle_list } from "../../redux/vehiclSlice";

const VehicleList = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState(5);
  const [page, setpage] = useState(1);
  const [initialValue, setInitialValue] = useState(1);
  const { vehicle_list, total_vehicle_count } = useSelector(
    (state) => state.vehicles
  );

  useEffect(() => {
    store.dispatch(get_vehicle_list({ page: page, rows: rows }));
  }, []);
  const onPrevious = () => {
    initialValue == 1 || setInitialValue(Number(initialValue) - Number(rows));
    page == 1 || setpage(page - 1);
    page === 1 ||
      store.dispatch(get_vehicle_list({ page: page - 1, rows: rows }));
  };

  const onNext = () => {
    rows * page >= total_vehicle_count ||
      setInitialValue(Number(initialValue) + Number(rows));
    rows * page >= total_vehicle_count || setpage(page + 1);
    rows * page >= total_vehicle_count ||
      store.dispatch(get_vehicle_list({ page: page + 1, rows: rows }));
  };
  return (
    <>
      <Card
        bg={"#fff"}
        paddingInline={5}
        paddingBlock={2}
        flexDirection={["column", "column", "row"]}
        align={["start", "start", "center"]}
      >
        <Heading fontSize={"xl"}>Vehicle List</Heading>
        <Spacer />
        <Flex alignItems={"center"} gap={2} mr={2}>
          <Select
            placeholder=""
            size={"md"}
            w={"15"}
            value={rows}
            onChange={(e) => {
              store.dispatch(
                get_vehicle_list({ page: page, rows: e.target.value })
              );
              setRows(e.target.value);
            }}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Select>
        </Flex>
        <InputGroup bg={"#f4f5f7"} maxW={"sm"} borderRadius={5}>
          <Input placeholder="Search..." />
          <InputRightElement>
            <FiSearch />
          </InputRightElement>
        </InputGroup>
      </Card>
      <SimpleGrid minChildWidth="xs" spacing="10px">
        {vehicle_list.map((el, i) => (
          <Card
            key={i}
            paddingInline={5}
            paddingBlock={2}
            cursor={"pointer"}
            onClick={() =>
              navigate("/vehicle-list/vehicle-detail", { state: el.id })
            }
          >
            <Flex justify={"space-between"}>
              <DisplayData
                fs={"16px"}
                v_color={"#007bc9"}
                fw={"bold"}
                value={el.vehicleno}
              />
              <DisplayData v_color={"#f37022"} fw={"bold"} value={el.type} />
            </Flex>

            <DisplayData title={"Make"} value={el.make} />
            <DisplayData title={"Chassis No."} value={el.chassis} />
            <DisplayData title={"Capacity"} value={el.capacity} />
          </Card>
        ))}
      </SimpleGrid>
      <Card
        alignItems={"center"}
        alignSelf={"end"}
        gap={3}
        flexDirection={"row"}
      >
        <FiChevronLeft
          cursor={"pointer"}
          color={page == 1 ? "gray" : "#007bc9"}
          size={35}
          onClick={onPrevious}
        />
        <Flex gap={3}>
          <Text>{initialValue}</Text>-
          <Text>
            {rows * page >= total_vehicle_count
              ? total_vehicle_count
              : rows * page}
          </Text>
          <Text>of</Text>
          <Text>{total_vehicle_count}</Text>
        </Flex>
        <FiChevronRight
          cursor={"pointer"}
          color={rows * page >= total_vehicle_count ? "gray" : "#007bc9"}
          size={35}
          onClick={onNext}
        />
      </Card>
    </>
  );
};

export default VehicleList;
