import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../utils/utils";

export const get_product_list = createAsyncThunk("product", async (data) => {
  const body = new FormData();
  body.append("userid", 1);
  body.append("ingress", 1);
  body.append("page", data.page);
  body.append("limit", data.rows);
  const response = await fetch(baseUrl + "productlist", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  console.log(res);
  return res;
});

const initialState = {
  product_list: [],
  loading: false,
  total_product_count: 0,
};
const productSlice = createSlice({
  name: "product",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(get_product_list.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(get_product_list.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(get_product_list.fulfilled, (state, action) => {
      state.loading = false;
      const data = action.payload;
      state.loading = false;
      if (data.success) {
        state.total_product_count = data.totalcount;
        state.product_list = data.reponselist;
      } else {
        state.product_list = [];
      }
    });
  },
});
export default productSlice.reducer;
