import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../utils/utils";

export const get_vehicle_list = createAsyncThunk(
  "vehiclelist",
  async (data) => {
    console.log(data);
    const body = new FormData();
    body.append("userid", 1);
    body.append("ingress", 1);
    body.append("action", 1);
    body.append("page", data.page);
    body.append("limit", data.rows);
    const response = await fetch(baseUrl + "vehiclelist", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    console.log(res);
    return res;
  }
);

const initialState = {
  vehicle_list: [],
  loading: false,
  total_vehicle_count: 0,
};
const vehicleSlice = createSlice({
  name: "users",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(get_vehicle_list.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(get_vehicle_list.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(get_vehicle_list.fulfilled, (state, action) => {
      state.loading = false;
      const data = action.payload;
      state.loading = false;
      if (data.success) {
        state.total_vehicle_count = data.totalcount;
        state.vehicle_list = data.reponselist;
      } else {
        state.vehicle_list = [];
      }
    });
  },
});
export default vehicleSlice.reducer;
