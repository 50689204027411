import { Box, Tag, Tooltip } from "@chakra-ui/react";
import React from "react";

const CustomCard = React.forwardRef(({ children, ...rest }, ref) => (
  <Tag ref={ref} {...rest} p={0} m={0} bg={"transparent"}>
    {children}
  </Tag>
));

const CustomToolTip = ({ button, mess }) => {
  return (
    <Tooltip
      borderRadius={4}
      backgroundColor={"gray"}
      fontWeight={"semibold"}
      fontSize={"sm"}
      label={mess}
      hasArrow
    >
      <CustomCard>{button}</CustomCard>
    </Tooltip>
  );
};

export default CustomToolTip;
