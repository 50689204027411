import React from "react";
import { Flex, HStack, Stack, VStack } from "@chakra-ui/react";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";

const Layout = () => {
  return (
    <VStack h={"100vh"} w={"100vw"} align={"flex-start"} spacing={0}>
      <HStack
        bg={"#fff"}
        w={"full"}
        h={"16"}
        // borderBottom={"1px solid #88888822 "}
      >
        <Header />
      </HStack>
      <Flex h={"full"} w={"full"} overflowY={"auto"}>
        <Stack>
          <Stack display={["none", "none", "none", "flex"]}>
            <div
              style={{
                position: "relative",
                width: "13rem",
                transition: "width ease 1s",
                // borderRight: "1px solid #88888822 ",
                overflow: "hidden",
              }}
            >
              <Sidebar />
            </div>
          </Stack>
        </Stack>
        <Stack
          h={"full"}
          w={"full"}
          bg={"#FAFBFE"}
          p={"20px"}
          overflowY={"auto"}
        >
          <Outlet />
        </Stack>
      </Flex>
    </VStack>
  );
};

export default Layout;
