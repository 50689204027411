import React, { useEffect, useState } from "react";
import store from "../../redux/store";
import {
  Card,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  SimpleGrid,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { FiChevronLeft, FiChevronRight, FiSearch } from "react-icons/fi";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import { useSelector } from "react-redux";
import { get_vendor_list } from "../../redux/vendorSlice";
import { useNavigate } from "react-router-dom";
import DisplayData from "../../components/DisplayData";

const VendorList = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState(5);
  const [page, setpage] = useState(1);
  const [initialValue, setInitialValue] = useState(1);
  const { vendor_list, total_vendor_count } = useSelector(
    (state) => state.vendors
  );

  useEffect(() => {
    store.dispatch(get_vendor_list({ page: page, rows: rows }));
  }, []);
  const onPrevious = () => {
    initialValue == 1 || setInitialValue(Number(initialValue) - Number(rows));
    page == 1 || setpage(page - 1);
    page === 1 ||
      store.dispatch(get_vendor_list({ page: page - 1, rows: rows }));
  };

  const onNext = () => {
    rows * page >= total_vendor_count ||
      setInitialValue(Number(initialValue) + Number(rows));
    rows * page >= total_vendor_count || setpage(page + 1);
    rows * page >= total_vendor_count ||
      store.dispatch(get_vendor_list({ page: page + 1, rows: rows }));
  };
  return (
    <>
      <Card
        bg={"#fff"}
        paddingInline={5}
        paddingBlock={2}
        flexDirection={["column", "column", "row"]}
        align={["start", "start", "center"]}
      >
        <Heading fontSize={"xl"}>Vendors List</Heading>
        <Spacer />
        <Flex alignItems={"center"} gap={2} mr={2}>
          {/* <Text>Item limit :</Text> */}
          <Select
            placeholder=""
            size={"md"}
            w={"15"}
            value={rows}
            onChange={(e) => {
              store.dispatch(
                get_vendor_list({ page: page, rows: e.target.value })
              );
              setRows(e.target.value);
            }}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Select>
        </Flex>
        <InputGroup bg={"#f4f5f7"} maxW={"sm"} borderRadius={5}>
          <Input placeholder="Search..." />
          <InputRightElement>
            <FiSearch />
          </InputRightElement>
        </InputGroup>
      </Card>
      <SimpleGrid minChildWidth="xs" spacing="10px">
        {vendor_list.map((el, i) => (
          <Card
            paddingInline={5}
            paddingBlock={2}
            cursor={"pointer"}
            onClick={() =>
              navigate("/vendors-list/vendor-detail", { state: el.id })
            }
          >
            <DisplayData
              fs={"16px"}
              v_color={"#169e49"}
              fw={"bold"}
              value={el.tradename}
            />
            <Flex justify={"space-between"}>
              <DisplayData title={"GST"} value={el.gst} />
              <DisplayData v_color={"#f37022"} value={el.vendorid} />
            </Flex>
            <DisplayData title={"Personal Mobile"} value={el.mobile_personal} />
            <DisplayData title={"State"} value={el.state} />
          </Card>
        ))}
      </SimpleGrid>
      <Card
        alignItems={"center"}
        alignSelf={"end"}
        gap={3}
        flexDirection={"row"}
      >
        <FiChevronLeft
          cursor={"pointer"}
          color={page == 1 ? "gray" : "#007bc9"}
          size={35}
          onClick={onPrevious}
        />

        <Flex gap={3}>
          <Text>{initialValue}</Text>-
          <Text>
            {rows * page >= total_vendor_count
              ? total_vendor_count
              : rows * page}
          </Text>
          <Text>of</Text>
          <Text>{total_vendor_count}</Text>
        </Flex>

        <FiChevronRight
          cursor={"pointer"}
          color={rows * page >= total_vendor_count ? "gray" : "#007bc9"}
          size={35}
          onClick={onNext}
        />
      </Card>
    </>
  );
};

export default VendorList;
