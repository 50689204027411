import React, { useEffect, useState } from "react";
import store from "../../redux/store";
import {
  Card,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  Td,
  Tr,
} from "@chakra-ui/react";
import { FiEdit, FiEye, FiSearch, FiUpload } from "react-icons/fi";
import { RiFileExcel2Fill } from "react-icons/ri";
import TableView from "../../components/TableView";
import { useSelector } from "react-redux";
import { get_product_list } from "../../redux/productSlice";
import CustomButton from "../../components/ButtonComp";
import CustomToolTip from "../../components/CustomTooltip";
import { useNavigate } from "react-router-dom";

const Products = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState(5);
  const [page, setpage] = useState(1);
  const [initialValue, setInitialValue] = useState(1);
  const { product_list, total_product_count } = useSelector(
    (state) => state.products
  );

  useEffect(() => {
    store.dispatch(get_product_list({ page: page, rows: rows }));
  }, []);

  return (
    <>
      <Card
        bg={"#fff"}
        p={2}
        flexDirection={"row"}
        alignItems={"center"}
        // justify={"space-between"}
        gap={3}
      >
        <InputGroup bg={"#f4f5f7"} maxW={"sm"} borderRadius={5}>
          <Input placeholder="Search..." />
          <InputRightElement>
            <FiSearch />
          </InputRightElement>
        </InputGroup>
        <Spacer />
        <CustomToolTip
          button={
            <FiUpload
              size={30}
              style={{
                backgroundColor: "#0175c2",
                padding: 5,
                borderRadius: 5,
                color: "#fff",
              }}
            />
          }
          mess={"Upload"}
        />
        <CustomButton title={"Show Price"} />
        <CustomToolTip
          button={
            <RiFileExcel2Fill
              size={30}
              style={{
                backgroundColor: "#0175c2",
                padding: 5,
                borderRadius: 5,
                color: "#fff",
              }}
            />
          }
          mess={"Excel"}
        />
        <CustomButton
          title={"Add Product"}
          onClick={() => navigate("/products/add-product")}
        />
      </Card>
      <div>
        <TableView
          headData={[
            "S.No.",
            "description",
            "category",
            "type",
            "capacity",
            "Action",
          ]}
          body={product_list.map((el, i) => (
            <Tr>
              <Td textAlign="center">{i + initialValue}</Td>
              <Td textAlign="center">{el.description}</Td>
              <Td textAlign="center">{el.category}</Td>
              <Td textAlign="center">{el.type}</Td>
              <Td textAlign="center">{el.capacity}</Td>
              <Td textAlign="center">
                {
                  <Flex justify={"center"} gap={4}>
                    <CustomToolTip
                      button={
                        <FiEye size={20} color="#0175c2" cursor={"pointer"} />
                      }
                      mess={"View Product"}
                    />
                    <CustomToolTip
                      button={<FiEdit size={20} cursor={"pointer"} />}
                      mess={"Edit Product"}
                    />
                  </Flex>
                }
              </Td>
            </Tr>
          ))}
          initialValue={initialValue}
          page={page}
          rows={rows}
          total_value={total_product_count}
          pagination
          onChange={(e) => {
            setpage(1);
            store.dispatch(get_product_list({ page: 1, rows: e.target.value }));
            setRows(e.target.value);
          }}
          onPrevious={() => {
            initialValue == 1 ||
              setInitialValue(Number(initialValue) - Number(rows));
            page == 1 || setpage(page - 1);
            page === 1 ||
              store.dispatch(get_product_list({ page: page - 1, rows: rows }));
          }}
          onNext={() => {
            rows * page >= total_product_count ||
              setInitialValue(Number(initialValue) + Number(rows));
            rows * page >= total_product_count || setpage(page + 1);
            rows * page >= total_product_count ||
              store.dispatch(get_product_list({ page: page + 1, rows: rows }));
          }}
        />
      </div>
    </>
  );
};

export default Products;
