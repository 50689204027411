import React from "react";
import "./App.css";
import { Outlet, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Layout from "./components/Layout";
import Dashboard from "./pages/dashboard";
import DriverList from "./pages/driver";
import Products from "./pages/product";
import VendorList from "./pages/vendor";
import VendorDetail from "./pages/vendor/VendorDetail";
import DriverDetail from "./pages/driver/DriverDetail";
import AddProduct from "./pages/product/AddProduct";
import StockPoint from "./pages/stockPoint";
import DPList from "./pages/DPList";
import DPdetail from "./pages/DPList/DPdetail";
import GodownList from "./pages/godown";
import GodownDetail from "./pages/godown/GodownDetail";
import VehicleList from "./pages/vehicle";
import VehicleDetail from "./pages/vehicle/VehicleDetail";
import PromoList from "./pages/promocode";
import CustomerList from "./pages/customerlist";
import CustomerDetail from "./pages/customerlist/CustomerDetail";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Dashboard />} />
        <Route path="driver-list" element={<Outlet />}>
          <Route index element={<DriverList />} />
          <Route path="driver-detail" element={<DriverDetail />} />
        </Route>
        <Route path="godown-list" element={<Outlet />}>
          <Route index element={<GodownList />} />
          <Route path="godown-detail" element={<GodownDetail />} />
        </Route>
        <Route path="vehicle-list" element={<Outlet />}>
          <Route index element={<VehicleList />} />
          <Route path="vehicle-detail" element={<VehicleDetail />} />
        </Route>
        <Route path="products" element={<Outlet />}>
          <Route index element={<Products />} />
          <Route path="add-product" element={<AddProduct />} />
        </Route>
        <Route path="vendors-list" element={<Outlet />}>
          <Route index element={<VendorList />} />
          <Route path="vendor-detail" element={<VendorDetail />} />
        </Route>
        <Route path="stock-point" element={<Outlet />}>
          <Route index element={<StockPoint />} />
        </Route>
        <Route path="dp-list" element={<Outlet />}>
          <Route index element={<DPList />} />
          <Route path="dp-detail" element={<DPdetail />} />
        </Route>
        <Route path="promo-list" element={<Outlet />}>
          <Route index element={<PromoList />} />
          <Route path="dp-detail" element={<DPdetail />} />
        </Route>
        <Route path="customer-list" element={<Outlet />}>
          <Route index element={<CustomerList />} />
          <Route path="customer-detail" element={<CustomerDetail />} />
        </Route>
      </Route>
      <Route path="login" element={<Login />} />
    </Routes>
  );
};

export default App;
