import { Flex, Text } from "@chakra-ui/react";
import React from "react";

const DisplayData = ({ title, value, mt, align, fs, v_color, fw, w }) => {
  return (
    <Flex gap={2} marginTop={mt || 1} align={align}>
      {title && (
        <Text fontSize={"sm"} color={"#000"} fontWeight={"medium"} w={w}>
          {title}
        </Text>
      )}
      {title && (
        <Text fontSize={"sm"} color={"#000"} fontWeight={"medium"}>
          :
        </Text>
      )}
      {value && (
        <Text
          fontSize={fs || "14px"}
          color={v_color || "#000"}
          fontWeight={fw || "semibold"}
          // overflow={"clip"}
          flex={1}
        >
          {value}
        </Text>
      )}
    </Flex>
  );
};

export default DisplayData;
