import {
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useState } from "react";
const ImageModal = ({ url = "", isOpen, onClose }) => {
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        url = "";
      }}
    >
      <ModalOverlay />
      <ModalContent maxW={"container.xl"} maxH={"2xl"} h={"full"} w={"full"}>
        <ModalCloseButton zIndex={100} bg={"#99999988"} />

        <ModalBody h={"full"}>
          {url?.split(".").pop().trim() == "pdf" ? (
            // {url?.split(/[#?]/)[0]?.split(".").pop().trim() == "pdf" ? (
            <iframe
              src={url}
              style={{ width: "100%", height: "100%" }}
              frameborder="1"
              loading="eager"
              onLoadStart={() => setLoading(true)}
              onLoadedData={() => setLoading(false)}
            ></iframe>
          ) : (
            <Image
              src={url}
              maxW={"container.xl"}
              w={"full"}
              objectFit={"contain"}
              maxH={"100%"}
              h={"full"}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ImageModal;
